import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'port-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.sass']
})
export class CareerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  career: Array<any> = [
    {
      time: "August 2018 - Current",
      title: {
        img: "logo_ey.svg",
        company: "EY wavespace",
        country: "Singapore",
        role: "Experience Designer & Front-end Developer",
      },
      desc: "Front-end developer in the pioneer team at EY Wavespace Singapore which specializes in solutioning with robotics, automation, blockchain & artificial intelligence for industries such as Energy, Oil & Gas, Governments in ASEAN markets. Creation of internal tools to expedite ‘concept to codes’ executions during workshops and hackathons."
    }, {
      time: "May 2017 - August 2018",
      title: {
        img: "logo_deloitte.svg",
        company: "Deloitte Digital",
        country: "Singapore",
        role: "Visual Designer (UI/UX) & Front-end Developer",
      },
      desc: "Integrating UI/UX design and front-end development in Deloitte Digital Singapore. Involvements include business development proposals and projects in Healthcare, Oil & Gas, Future of Work, pharmaceutical, and automobile industry in Southeast Asia market such as Vietnam, Singapore, Malaysia and Thailand."
    }, {
      time: "September 2016 - March 2017",
      title: {
        img: "logo_fixx.svg",
        company: "Fixx Digital",
        country: "Malaysia, Kuala Lumpur",
        role: "Team Leader",
      },
      desc: "To create and lead the expansion of the company to Malaysia, Kuala Lumpur. His responsibility was to oversee the hiring process and integrate the training of the new employees to fit the company’s culture."
    }, {
      time: "January 2015 - September 2016",
      title: {
        img: "logo_fixx.svg",
        company: "Fixx Digital",
        country: "Singapore",
        role: "Web Developer",
      },
      desc: "He was responsible for creating and executing the technical approach for all clients products."
    }, {
      time: "April 2012 - November 2012",
      title: {
        img: "logo_possible.svg",
        company: "Possible Worldwide",
        country: "Singapore",
        role: "Freelance UI/UX Designer",
      },
      desc: "He was heavily involved in a few tablet application designs for SAP Clients."
    }
  ]

}
