import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'port-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.sass']
})
export class ProjectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
