import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { GetOthersService } from '../../services'

@Component({
  selector: 'port-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.sass']
})

export class OthersComponent implements OnInit {
  _others: any
  destroy$: Subject<boolean> = new Subject<boolean>();

  public config: SwiperConfigInterface = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    initialSlide: 0
  };

  constructor(private other: GetOthersService) { }

  ngOnInit() {
    this.other.getOthers().pipe(takeUntil(this.destroy$)).subscribe((data: any[]) => {
      this._others = data
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
