import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { GetFeaturedService } from '../../services';

@Component({
  selector: 'port-works-details',
  templateUrl: './works-details.component.html',
  styleUrls: ['./works-details.component.sass']
})
export class WorksDetailsComponent implements OnInit, OnDestroy {

  _featureSlug: string
  _feature: any
  _currentFeatured: any
  destroy$: Subject<boolean> = new Subject<boolean>();

  _detailCurrent: number
  _detailVideo: string
  _detailLength: number

  _detailNext: number
  _detailNextSlug: string
  _detailNextTitle: string

  _detailPrev: number
  _detailPrevSlug: string
  _detailPrevTitle: string

  _currentMod: number = 1

  constructor(
    private feature: GetFeaturedService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this._featureSlug = this.route.snapshot.paramMap.get("slug")
    this.getFeatured(this._featureSlug)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getFeatured(slug:string){
    this.feature.getFeatured().pipe(takeUntil(this.destroy$)).subscribe((data: any[]) => {
      this._feature = data
      
      this._currentFeatured = data.filter(feature => {
        return feature.slug === slug;
      })
      
      this._detailLength = data.length
      this._detailCurrent = this._currentFeatured[0].id
      this._detailVideo = "assets/imgs/works/featured/" + this._currentFeatured[0].slug + "/" + this._currentFeatured[0].details.asset.vid

      this._detailNext = this._detailCurrent == this._detailLength ? this._detailNext = 1 : this._detailNext = this._detailCurrent + 1
      this._detailNextSlug = this._feature[this._detailNext - 1].slug
      this._detailNextTitle = this._feature[this._detailNext - 1].details.title

      this._detailPrev = this._detailCurrent == 1 ? this._detailPrev = this._detailLength : this._detailPrev = this._detailCurrent - 1
      this._detailPrevSlug = this._feature[this._detailPrev - 1].slug
      this._detailPrevTitle = this._feature[this._detailPrev - 1].details.title

      console.log(this._detailVideo)
    })
  }

  nav(url:string) {
    this.router.navigate(['works/'+url]).then(() => {
      this._featureSlug = this.route.snapshot.paramMap.get("slug")
      this._currentMod = 1
      this.getFeatured(this._featureSlug)
    });
  }

  changeMod(mod:number){
    this._currentMod = mod
  }

}
