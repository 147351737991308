import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'port-competencies',
  templateUrl: './competencies.component.html',
  styleUrls: ['./competencies.component.sass']
})
export class CompetenciesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  competencies: Array<any> = [
    {
      title: "Design",
      list: [
        "Generate digital solutions and articulate ideas/concepts to stakeholders, clients and colleagues.",
        "Knowledge of industry standard prototyping tools such as Figma, Sketch, InVision, Adobe XD, Adobe Photoshop & Adobe Illustrator.",
        "High importance in content, intent of layout and visual elements to see the results through for user-testing with real users. Good knowledge about typography and colour psychology."
      ]
    }, {
      title: "Development",
      list: [
        "Knowledge of HTML, JADE, CSS, SASS and JavaScript/jQuery.",
        "Awareness of the Web Development industry, and new technologies.",
        "Strategically thinking through all parts of the design process.",
        "Knowledge of international web standards and protocols.",
        "Javascript Frameworks: AngularJs, Angular, React, ReactNative."
      ]
    }, {
      title: "Professional",
      list: [
        "Able to organise own workload effectively and prioritise tasks.",
        "Strong attention to details.",
        "Can quickly understand business requirements and translate it into functional requirements.",
        "Can manage multiple projects in a fast-paced, deadline-driven environment.",
        "Adaptable and able to quickly pick up new techniques.",
        "Understanding of W3C standards, web accessibility & best practice.",
      ]
    }, {
      title: "Personal",
      list: [
        "Passionate in delivering valuable and quality experience in product development.",
        "Confident, friendly and easy to get along with.",
        "Comfortable with being the ‘go to’ person in a company.",
        "First rate interpersonal and communication skills, able to easily interact with fellow developers and customers alike.",
        "Strongly committed to all projects from inception right through to the end."
      ]
    }
  ]

}
