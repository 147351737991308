import { Component, OnInit } from '@angular/core';

declare let BlobAnimation: any;
declare let jsband: any;
@Component({
  selector: 'port-dots',
  templateUrl: './dots.component.html',
  styleUrls: ['./dots.component.sass']
})

export class DotsComponent implements OnInit {
  dots:any

  constructor() {
  }

  ngOnInit() {
    this.dots = new BlobAnimation('dots', {
		//Blob main settings
		BLOB_SIZE: 220,
		BLOB_DISTANCE: 0,
		DETALISATION: 60,
		PERSPECTIVE_DISTORTION: 5,
		ROTATION_SPEED: 2,
		
		//Dot settins
		DOT_SIZE: 1,
		DOT_COLOR: "gray",
			
		//Mouse settings				
		MOUSE_DISTANCE_MIN: 20,
		MOUSE_DISTANCE_MAX: 20000,
		MOUSE_SENSITIVITY: 0.1,
		INERTIAL_TIME: 2,
		
		//Shape settins
		//Shape main settings
		INITIAL_SHAPE: {
			wave1: {amplitude: 70, frequency: 3, phase: 0},
			wave2: {amplitude: 60, frequency: 2, phase: 0},
			wave3: {amplitude: 50, frequency: 2, phase: 0}
		},
		USE_MORPHING: false,
		USE_WAVE_MOTION: true,
		USE_WAVE_SWING: false,
		//Wave motion settings
		WAVE_1_MOTION_SPEED: 0.85,
		WAVE_2_MOTION_SPEED: 0.5,
		WAVE_3_MOTION_SPEED: 1.5
	})

	setInterval( ()=> {
		jsband.ColorTween.run(
			this.dots, 
			"dotColor",
			"rgb(" + 255*Math.random() + "," + 255*Math.random() + "," + 255*Math.random() + ")",
			jsband.Ease.lin(),
			1000
		)
	}, 10000)
  }

}
