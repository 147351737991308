import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { KnowComponent } from './know';

@NgModule({
  declarations: [KnowComponent],
  imports: [CommonModule, RouterModule]
})
export class KnowModule { }
