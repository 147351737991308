import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DotsComponent } from './dots';
import { ErrorComponent } from './error';


@NgModule({
  declarations: [DotsComponent, ErrorComponent],
  imports: [CommonModule, RouterModule],
  exports: [DotsComponent, ErrorComponent]
})
export class SharedModule { }
