import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LabsComponent } from './labs/labs.component';

@NgModule({
  declarations: [LabsComponent],
  imports: [CommonModule, RouterModule]
})
export class LabsModule { }
