import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GetFeaturedService } from '../../services';
@Component({
  selector: 'port-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.sass']
})
export class FeaturedComponent implements OnInit, OnDestroy {
  
  _featured: any
  _currentSlide: number = 1
  _totalSlides: number = 3

  _prevSlide: number
  _prevSlideTitle: string

  _nextSlide: number
  _nextSlideTitle: string
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private feature: GetFeaturedService) {}

  ngOnInit() {
    this.feature.getFeatured().pipe(takeUntil(this.destroy$)).subscribe((data: any[]) => {
      this._featured = data
      this._nextSlide = this._currentSlide + 1
      this._nextSlideTitle = this._featured[this._nextSlide - 1].details.title

      this._prevSlide = this._totalSlides
      this._prevSlideTitle = this._featured[this._prevSlide - 1].details.title
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  changeSlide(state: string){
    if(state == 'next') {
      this._currentSlide = this._currentSlide == this._totalSlides ? this._currentSlide = 1 : this._currentSlide = this._currentSlide + 1
    } else {
      this._currentSlide = this._currentSlide == 1 ? this._currentSlide = this._totalSlides: this._currentSlide = this._currentSlide - 1
    }

    this._nextSlide = this._currentSlide == this._totalSlides ? this._nextSlide = 1 : this._nextSlide = this._currentSlide + 1
    this._nextSlideTitle = this._featured[this._nextSlide - 1].details.title

    this._prevSlide = this._currentSlide == 1 ? this._prevSlide = this._totalSlides : this._prevSlide = this._currentSlide - 1
    this._prevSlideTitle = this._featured[this._prevSlide - 1].details.title
  }

}
