import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(private http: HttpClient) { }

  apiUrl: string = "https://api.openweathermap.org/data/2.5";
  apiKey: string = "ea662667b2cc21d352b4b571785164f7";

  getCurrentWeather(loc: string) {
    return this.http.get(`${this.apiUrl}/weather?q=${loc}&appid=${this.apiKey}`)
  }
}
