import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { HomeComponent } from "./home";
import { AboutComponent } from "./about";
import { ContactComponent } from "./contact";
import { WorksComponent, WorksDetailsComponent } from "./works";
import { KnowComponent } from "./know";
import { LabsComponent } from "./labs";
import { ErrorComponent } from "./shared";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },{
    path: "about",
    component: AboutComponent
  }, {
    path: "contact",
    component: ContactComponent
  }, {
    path: "works",
    component: WorksComponent
  }, {
    path: "works/:slug",
    component: WorksDetailsComponent
  }, {
    path: "know",
    component: KnowComponent
  }, {
    path: "labs",
    component: LabsComponent
  }, {
    path: "404",
    component: ErrorComponent
  }, {
    path: "**",
    component: ErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
