import { Component, OnInit, Inject, ElementRef, Renderer2 } from '@angular/core';
import { WeatherService } from './services';
import { DOCUMENT } from "@angular/common";
import { WINDOW } from "./services";

@Component({
  selector: 'port-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  host: {
    '(document:scroll)': 'onScroll()',
    '(document:mousemove)': 'onMouseMove($event)'
  }
})

export class AppComponent implements OnInit{
  loc: string = 'singapore'
  currentWeather: any = <any>{}
  msg: string
  _navState: boolean = false

  transform: any
  scroll: number = 0

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    @Inject(WINDOW) private window: Window,
    private el: ElementRef,
    private renderer: Renderer2,
    private weatherService: WeatherService
  ){} 

  ngOnInit() {
    this.searchWeather(this.loc);
    const aPromise = new Promise((resolve, reject) => { setTimeout(resolve, 5000); });
    aPromise.then(result => { this.searchWeather(this.loc); }).catch(error => { console.log(`Something went wrong - ${error}`) })
  }

  onScroll(){
    this.scroll = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
  }

  onMouseMove(e: any) {
    let mouseX = e.pageX + 15
    let mouseY = e.pageY - this.scroll + 15
    this.transform = 'translate(' + mouseX + 'px,' + mouseY + 'px)' 
  }
  
  searchWeather(loc: string) {
    this.msg = '';
    this.currentWeather = {};
    this.weatherService.getCurrentWeather(loc).subscribe(res => {
        this.currentWeather = res;
      }, err => {
        if (err.error && err.error.message) {
          alert(err.error.message);
          this.msg = err.error.message;
          return;
        }
        alert('Failed to get weather.');
      }, () => {})
  }

  resultFound() {
    return Object.keys(this.currentWeather).length > 0;
  }

  navState(){
    this._navState = !this._navState;
  }
}
