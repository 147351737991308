import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { TitleComponent } from './title/';
import { FeaturedComponent } from './featured';
import { OthersComponent } from './others';

import { WorksComponent } from './works';
import { WorksDetailsComponent } from './works-details';

import { SharedModule } from './../shared';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  initialSlide: 0,
  observer: true,
  observeParents: true,
  grabCursor: false,
  normalizeSlideIndex: true,
  a11y: true,
  preventClicks: true
};

@NgModule({
  declarations: [
    WorksComponent,
    WorksDetailsComponent,
    TitleComponent,
    FeaturedComponent,
    OthersComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    SwiperModule,
    SharedModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class WorksModule { }
