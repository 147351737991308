import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { ContactComponent } from './contact';
import { TitleComponent } from './title';
import { MessageComponent } from './message';
import { ProjectComponent } from './project';

@NgModule({
  declarations: [ContactComponent, TitleComponent, MessageComponent, ProjectComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule
  ]
})
export class ContactModule { }
